<template>
    <div class="border-t py-4 mx-4">

        <!--- PROGRESSBAR -->
        <div class="flex justify-between px-2 my-4 relative z-10">
            <div class="absolute w-[calc(100%-2rem)] h-2 bg-primary-200 -z-10 top-1/2 -translate-y-1/2">
                <div class="absolute left-0 top-0 w-0 h-full bg-primary-500 z-10 transition-all"
                    :style="`width: ${((currentStage - 1) / (data.length - 1)) * 100}%`"></div>
            </div>
            <div v-for="stage in data" :key="stage.stage"
                class="bg-primary-200 w-10 h-10 flex justify-center items-center text-white rounded-full border border-primary-400"
                :class="{ 'bg-primary-500': currentStage >= stage.stage }">
                <Icon :name="stage.icon" />
            </div>
        </div>
        <!---    -->


        <div v-for="stages in data" :key="stages.stage">
            <div class="w-full" :id="`form-${stages.stage}`" v-show="currentStage === stages.stage">
                <span class="font-bold text-center text-gray-600 uppercase w-full block">{{ stages.title }}</span>
                <div class="flex flex-col gap-3">
                    <label v-for="field in stages.fields" :key="field.id" class="flex flex-col gap-2">
                        <span v-if="field.type !== 'custom'"><span class="font-bold">{{ field.title }}</span><span
                                class="text-red-400">*</span></span>
                        <template v-if="field.type === 'text'">
                            <input type="text" :placeholder="field.placeholder" :required="field.required"
                                class="p-2 border border-gray-200 rounded" v-model="form[field.id]" />
                        </template>
                        <template v-else-if="field.type === 'select'">
                            <select class="p-2 border border-gray-200 rounded pr-4" v-model="form[field.id]"
                                :required="field.required">
                                <option :value="key" v-for="(option, key) in field.options">{{ option }}</option>
                            </select>
                        </template>
                        <template v-else-if="field.type === 'custom'">
                            <div class="bg-red-400 p-4 border border-red-600 rounded-lg mt-4 mb-4">
                                <span class="font-bold text-red-900">Give a star to Pathway repositories</span>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                                    <a href="https://github.com/pathwaycom/pathway" target="_blank">
                                        <img src="https://opengraph.githubassets.com/f06c0cab032a6e53d8228251b78f489e2e8867976035909a6327b44685c685d6/pathwaycom/pathway"
                                            class="w-full h-auto object-cover" />
                                    </a>
                                    <a href="https://github.com/pathwaycom/llm-app" target="_blank">
                                        <img src="https://repository-images.githubusercontent.com/668195240/f58d7a42-3f95-49d5-a3fd-b6972bff7834"
                                            class="w-full h-auto object-cover" />
                                    </a>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <input :type="field.type" :placeholder="field.placeholder" :required="field.required"
                                class="p-2 border border-gray-200 rounded" v-model="form[field.id]" />
                        </template>
                        <span class="text-xs text-gray-600" v-if="field.description">{{ field.description }}</span>
                    </label>
                </div>
            </div>

        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    data: Array
})
const emit = defineEmits(['formChange'])
const { page } = useContent()

const currentStage = useState(`form-${page.value._dir}`)

const form = ref({})

watch(form.value, async (newForm, oldForm) => {
    emit('formChange', newForm)
})
</script>
